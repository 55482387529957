@import "public/static/vendor/bootstrap-sass/bootstrap";

div.wpcf7 {
  margin: 0;
  padding: 0;
}

div.wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.2em 1em;
}

div.wpcf7 .screen-reader-response {
  margin: 0;
  padding: 0;
  border: 0 currentColor;
  border-image: none;
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
}

div.wpcf7-mail-sent-ok {
  border: 2px solid rgb(57, 143, 20);
  border-image: none;
}

div.wpcf7-mail-sent-ng {
  border: 2px solid rgb(255, 0, 0);
  border-image: none;
}

div.wpcf7-spam-blocked {
  border: 2px solid rgb(255, 165, 0);
  border-image: none;
}

div.wpcf7-validation-errors {
  border: 2px solid rgb(247, 231, 0);
  border-image: none;
}

.wpcf7-form-control-wrap {
  position: relative;
}

span.wpcf7-not-valid-tip {
  color: rgb(255, 0, 0);
  font-size: 1em;
  display: block;
}

.use-floating-validation-tip span.wpcf7-not-valid-tip {
  background: rgb(255, 255, 255);
  padding: 0.2em 0.8em;
  border: 1px solid rgb(255, 0, 0);
  border-image: none;
  left: 20%;
  top: 20%;
  position: absolute;
  z-index: 100;
}

span.wpcf7-list-item {
  margin-left: 0.5em;
}

.wpcf7-display-none {
  display: none;
}

div.wpcf7 img.ajax-loader {
  border: currentColor;
  border-image: none;
  margin-left: 4px;
  vertical-align: middle;
}

div.wpcf7 div.ajax-error {
  display: none;
}

div.wpcf7 .placeheld {
  color: rgb(136, 136, 136);
}